import React from "react";
import { Button, Form, } from 'react-bootstrap';
import './StepOne.scss';
import ScrollAnimation from 'react-animate-on-scroll';

const StepOne = (props) => {

  return (
    <div className="stepone">
      <div className="d-lg-block">
        <div onClick={props.handleBack} onKeyPress={props.handleBack} className="back_icon"><i className="icon-back"></i> Back to Property Details</div>
      </div>
      <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
        <h2>So you want to book a viewing. Let’s get started.</h2>
      </ScrollAnimation>
      <div className="form-section">
        <div className="form-block-col">
          <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
            <Form.Group>
              <Form.Control className={props.nameError ? 'error-border' : ''} autoComplete="off" value={props.name} onChange={props.onChange} name="name" type="text" placeholder="Your First Name" />
              {props.nameError &&
                <div className="error">
                  Please Enter First name.
                </div>
              }
            </Form.Group>
            <Form.Group>
              <Form.Control className={props.lastNameError ? 'error-border' : ''} autoComplete="off" value={props.lastName} onChange={props.onChange} name="lastName" type="text" placeholder="Your Last Name" />
              {props.lastNameError &&
                <div className="error">
                  Please Enter Last name.
                </div>
              }
            </Form.Group>
            <Form.Group>
              <Form.Control className={props.emailError ? 'error-border' : props.valemailError ? 'error-border' : ''} autoComplete="off" value={props.email} onChange={props.onChange} name="email" type="email" placeholder="Email Address" />
              {props.emailError &&
                <div className="error">
                  Please Enter Email.
                </div>
              }
              {props.valemailError && !props.emailError &&
                <div className="error">
                  Please Enter Valid Email.
                </div>
              }
            </Form.Group>
            <Form.Group>
              <Form.Control maxLength={13} className={props.phoneError ? 'error-border' : props.valphoneError ? 'error-border' : ''} autoComplete="off" value={props.phone} onChange={props.onChange} name="telephone" type="text" placeholder="Telephone Number" />
              {props.phoneError &&
                <div className="error">
                  Please Enter Telephone.
                </div>
              }
              {props.valphoneError && !props.phoneError &&
                <div className="error">
                  Please Enter Valid Telephone.
                </div>
              }
            </Form.Group>
            <Form.Group>
              <Button variant="primary" className={'ful'} onClick={props.next}>Get Started</Button>
            </Form.Group>
          </ScrollAnimation>


        </div>
      </div>
    </div>
  )
}



export default StepOne
