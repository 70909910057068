import React from "react";
import { Button, Form, } from 'react-bootstrap';
import './StepTwo.scss';
import ScrollAnimation from 'react-animate-on-scroll';

const StepTwo = (props) => {

  return (

    <div className="steptwo">
      <div className="d-lg-block">
        <div onClick={props.handleBack} onKeyPress={props.handleBack} className="back_icon"><i className="icon-back"></i> Back to Property Details</div>
      </div>
      <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
        <h2><span>Thanks {props.name}. We now need</span> to know how we can best help you.</h2>
      </ScrollAnimation>
      <div className="form-field">
        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <div className="option-bx">
            <h3>Have you got a property to sell?</h3>
            <div className="d-flex">
              <Form.Group controlId="sellYes">
                <Form.Check
                  onChange={props.onChange}
                  inline
                  checked={props.propertySell === 'Yes'}
                  type="radio"
                  value="Yes"
                  name="propertysell"
                  label="Yes" />
              </Form.Group>
              <Form.Group controlId="sellNo">
                <Form.Check
                  onChange={props.onChange}
                  checked={props.propertySell === 'No'}
                  inline
                  type="radio"
                  value="No"
                  name="propertysell"
                  label="No" />
              </Form.Group>
              {!props.propertySell && props.submitted &&
                <div className="error">
                  Please choose an option.
                </div>
              }
            </div>
          </div>
          <div className="option-bx">
            <h3>Is it on the market?</h3>
            <div className="d-flex">
              <Form.Group controlId="marketYes">
                <Form.Check
                  onChange={props.onChange}
                  checked={props.market === 'Yes'}
                  inline
                  type="radio"
                  name="market"
                  label="Yes"
                  value="Yes" />
              </Form.Group>
              <Form.Group controlId="marketNo">
                <Form.Check
                  onChange={props.onChange}
                  inline
                  checked={props.market === 'No'}
                  type="radio"
                  name="market"
                  label="No"
                  value="No" />
              </Form.Group>
              {!props.market && props.submitted &&
                <div className="error">
                  Please choose an option.
                </div>
              }
            </div>
          </div>
          <div className="option-bx">
            <h3>How are you funding your purchase?</h3>
            <div className="d-flex">
              <Form.Group controlId="purchaseYes">
                <Form.Check
                  onChange={props.onChange}
                  inline
                  type="radio"
                  name="purchase"
                  label="Mortgage"
                  checked={props.purchase === 'Mortgage'}
                  value="Mortgage" />
              </Form.Group>
              <Form.Group controlId="purchaseNo">
                <Form.Check
                  onChange={props.onChange}
                  inline
                  type="radio"
                  name="purchase"
                  label="Cash Funds"
                  checked={props.purchase === 'Cash Funds'}
                  value="Cash Funds" />
              </Form.Group>
              {!props.purchase && props.submitted &&
                <div className="error">
                  Please choose an option.
                </div>
              }
            </div>
          </div>
          <div className="option-bx">
            <h3>Do you need a mortgage?</h3>
            <div className="d-flex">
              <Form.Group controlId="mortgageYes">
                <Form.Check
                  onChange={props.onChange}
                  checked={props.mortgage === 'Yes'}
                  inline
                  type="radio"
                  name="mortgage"
                  label="Yes"
                  value="Yes" />
              </Form.Group>
              <Form.Group controlId="mortgageNo">
                <Form.Check
                  onChange={props.onChange}
                  checked={props.mortgage === 'No'}
                  inline
                  type="radio"
                  name="mortgage"
                  label="No"
                  value="No" />
              </Form.Group>
              {!props.mortgage && props.submitted &&
                <div className="error">
                  Please choose an option.
                </div>
              }
            </div>

          </div>
          <div className="flex-btn">
            <Button className="order-btn-2" variant="primary" onClick={props.previous}>Back</Button>
            <Button className="order-btn-1" onClick={props.next} variant="primary" >
              Next Step
            </Button>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  )
}



export default StepTwo