import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import "react-calendar/dist/Calendar.css"
import "./CalendarBlock.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import enGb from "date-fns/locale/en-GB"
import ScrollAnimation from "react-animate-on-scroll"
import moment from "moment"

registerLocale("en-gb", enGb)

const CalendarBlock = props => {
  const [currentTime, seCurrenttime] = useState(null)
  const [startDate, setStartDate] = useState(
  )

  const [today, setToday] = useState(new Date())
  const [selday, setselday] = useState('')

  // const [startingDate, setStartingDate] = useState(
  // )

  let start = moment(new Date()).subtract(moment(new Date()).day() - 1, "days")
  let inclusedDae = [new Date(start._d)]
  for (let i = 1; i < 7; i++) {
    inclusedDae.push(new Date(moment(start).add(i, "days")._d))
  }

  useEffect(() => {
    let dateNow = moment().format()
    let splitdate = dateNow.split('T')
    let splitTime = splitdate[1].split(':')
    let nowtime = splitTime[0]
    seCurrenttime(nowtime)
    setToday(splitdate[0])
    setselday(splitdate[0])
  }, []);
  const onSelectDate = (date) => {
    let dateNow = moment(date).format()
    let splitdate = dateNow.split('T')
    setselday(splitdate[0])
    sessionStorage.setItem('date', dateNow)
  }
  return (
    <div className="calendar-block">
      <div className="back-bt">
        <div onClick={props.handleBack} onKeyPress={props.handleBack} className="back_icon"><i className="icon-back"></i> Back to Property Details</div>
      </div>
      <ScrollAnimation animateIn="fadeIn">
        <h2>Lastly, please choose the time and date of your viewing.</h2>
      </ScrollAnimation>
      <div>
        <ScrollAnimation animateIn="fadeIn">
          <div className="d-none d-md-block  desktop_view_date">
            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={date => {
                setStartDate(date)
                onSelectDate(date)
              }}
              showTimeSelect
              showTimeInput
              timeFormat="HH:mm"
              timeIntervals={60}
              locale="en-gb"
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              excludeTimes={
                selday === today ?
                  [
                    setHours(setMinutes(new Date(), 0), currentTime > 8 ? 8 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 10 ? 10 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 12 ? 12 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 14 ? 14 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 16 ? 16 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 18 ? 18 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 20 ? 20 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 21 ? 21 : 0)
                  ] : []}
              inline
            />
          </div>
          <div className="d-block d-md-none mobile_view_date">

            <DatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={date => {
                setStartDate(date)
                onSelectDate(date)
              }}
              showTimeSelect
              showTimeInput
              timeFormat="HH:mm"
              timeIntervals={60}
              locale="en-gb"
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
              excludeTimes={
                selday === today ?
                  [
                    setHours(setMinutes(new Date(), 0), currentTime > 8 ? 8 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 10 ? 10 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 12 ? 12 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 14 ? 14 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 16 ? 16 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 18 ? 18 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 20 ? 20 : 0),
                    setHours(setMinutes(new Date(), 0), currentTime > 21 ? 21 : 0)
                  ] : []}
              inline
            />
          </div>
        </ScrollAnimation>
      </div>
      {startDate ?
        <Button onClick={props.next} variant="primary">
          Confirm Date & Time
        </Button>
        : null}
      <Button variant="primary" onClick={props.previous}>Back</Button>
    </div>
  )
}

export default CalendarBlock
